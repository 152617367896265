import Vue from 'vue'
import {Message} from 'element-ui'
import axios from 'axios'
import router from '@/router'
import Cookies from 'js-cookie'
import {debounce} from './delay.js'

const request = axios.create({
   baseURL: process.env.VUE_APP_API_URL,
   timeout: 100000
})

const warning = debounce(message => {
   Message({
     message,
     type: "error"
   });
}, 500,true);

request.interceptors.request.use(req=>{
   req.headers.token=window.sessionStorage.getItem('token')   
   // req.headers.token='75526e9c-9515-48b0-b1b9-82e565826601'   
   return req
},err=>{
   return Promise.reject(err);
})
request.interceptors.response.use(res=>{
   if(res.data.code==900){
      warning(res.data.message)
      router.replace('/login')
      window.sessionStorage.clear()
      Cookies.remove('token')
      return
   }
   return res
},err=>{
      warning('服务器内部错误')
      return Promise.reject(err);
})


export default request