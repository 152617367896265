<template>
    <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
        <ShopsAndSites
        v-if="tress.length!=0"
        style="margin-right:10px"
        :list='tress'
        @getShopId='getID'></ShopsAndSites>
        <el-select clearable v-model="pageInfo.status" style="width：150px;margin-right:10px" placeholder="请选择商品状态" @change="find">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in productStatusList"
            :key="item.id"></el-option>
        </el-select>
        <el-select v-model="pageInfo.stockStatus" clearable style="width：150px;margin-right:10px" placeholder="请选择库存状态" @change="find">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in stockStatusList"
            :key="item.id"></el-option>
        </el-select>
        <el-input style="width:300px;margin-right:10px" placeholder="请输入ASIN" v-model="keyword" class="input-with-select" @keyup.enter.native = "find">
            <el-select clearable @clear="clearType" style="width:130px" v-model="select" slot="prepend" placeholder="选择输入类型" @change="find">
                <el-option 
                :label="item.value"
                :value="item.id"
                v-for="item in searchList"
                :key="item.id"></el-option>
            </el-select>
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="find">查询</el-button><br>
        <div style="margin-top:10px">
          <el-button type="primary" @click="batchSettingCost({})">批量设置成本</el-button>
          <el-button type="primary">批量删除商品</el-button>
          <!-- <el-button type="primary">批量上传真实成本</el-button> -->
        </div>
        <el-table
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          :data="tableData"
          style="width: 100%;margin-top:10px"
          :cell-style="{ fontSize: '13px', padding: '7px 0' }"
          :header-cell-style="{
              fontSize: '13px',
              padding: '9px 0',
              marginTop: '10px',
              background: '#EBEEF5',
          }">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column prop="date" label="商品信息">
            <template slot-scope="scope">
              <!-- <el-popover
                transition="fade-in-linear"
                close-delay='30'
                placement="right"
                width="520"
                trigger="hover"
              >
                <div style="display:flex">
                      <div class="pic">
                          <span v-if="!scope.row.imageUrl">暂无图片</span>
                          <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                      </div>
                      <div class="suspend" style="width:590px;font-size:14px">
                          <p class="infoText">{{scope.row.title}}</p>
                          <p style="margin-bottom:10px"><span><img style="width:24px;height:16px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>子ASIN:{{scope.row.asin}}</span><span>父ASIN:{{scope.row.parentAsin}}</span></p>
                          <p><span>SKU:{{scope.row.commoditySku}}</span><span>品牌:{{scope.row.label}}</span></p>
                      </div>
                </div>
                <div class="goodsInfo" slot="reference">
                      <div class="noPic">
                          <span v-if="!scope.row.imageUrl">暂无图片</span>
                          <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                      </div>
                      <div style="text-align:left">
                          <p><img style="width:24px;height:16px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>子ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
                          <p>SKU:&nbsp;&nbsp;{{scope.row.commoditySku}}</p>
                      </div>
                </div>
              </el-popover> -->
              <GoodsImage
                :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                :label="scope.row.label"
                :parentASIN="scope.row.parentAsin"
                :imageUrl="scope.row.imageUrl"
                :asin="scope.row.asin"
                :title="scope.row.title"
                :commoditySku="scope.row.commoditySku"
              ></GoodsImage>
            </template>
          </el-table-column>
          <el-table-column prop="statusName">
            <template slot="header">
              <span>商品状态</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <span>商品的销售状态</span><br/>
                  <span>绿色为可售, 紫色为不可售或未完成。</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip> 
            </template>
          </el-table-column>
          <el-table-column prop="purchasePrice">
            <template slot="header">
              <span>采购成本</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <span>从供货商处购买的单件商品采购价格。</span><br/>
                  <span>需自行设置，才会显示哦~</span><br/>
                  <span>可单一或者批量设置，还可下载模板填写后上传进行批量设置。</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.currency}}</span> <span>{{scope.row.purchasePrice}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="headerPrice">
            <template slot="header">
              <span>物流成本(头程/FBM)</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <span>将商品运送到亚马逊FBA仓或海外仓的单件商品物流费用, </span><br/>
                  <span>或者将单件商品运送到客户收货地址的物流费用。</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.currency}}</span> <span>{{scope.row.headerPrice}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="otherFee">
            <template slot="header">
              <span>其他费用</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <span>单件商品除了采购成本和物流成本以外的, 其它总成本费用, </span><br/>
                  <span>比如：包装费、增值税、货损费等。</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.currency}}</span> <span>{{scope.row.otherFee}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="grossProfitTarget">
            <template slot="header">
              <span>毛利指标</span>
              <!-- <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <span>将商品运送到亚马逊FBA仓或海外仓的单件商品物流费用, </span><br/>
                  <span>或者将单件商品运送到客户收货地址的物流费用。</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip> -->
            </template>
            <template slot-scope="scope">{{scope.row.grossProfitTarget}}%</template>
          </el-table-column>
          <el-table-column
            prop="prop"
            label="操作"
            width="150"
            >
            <template slot-scope="scope">
              <el-button type="text" class="el-icon-s-tools a" @click="batchSettingCost(scope.row.id,scope.row)">设置成本</el-button>
              <!-- <el-button type="text" class="el-icon-time a"></el-button>
              <el-button type="text" class="el-icon-delete-solid a"></el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:center;margin-top:20px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <el-dialog
          title="设置成本"
          :visible.sync="settingCostBox"
          width="45%"
          :before-close="Close">
          <div>
            <div class="picInfo" v-if="settingInfo.ids.length==1">
              <div class="pic">
                <img style="vertical-align:top" :src="pic" alt="" width="100%">
              </div>
              <div class="info">
                <p><span><img style="width:24px;height:16px;margin:0 6px 0 0" :src="marketPlaceImageUrl" alt=""/>子：{{asin}}</span> <span>父：{{parentAsin}}</span></p>
                <p><span>SKU：{{commoditySku}}</span> <span>品牌：{{label}}</span></p>
              </div>
            </div>
            <div 
            v-else
            style="overflow:auto;white-space:nowrap">
              <div
              v-for="(item,index) in imageList"
              :key="index"
              style="display:inline-block;width:100px;height:100px;margin-right:12px">
                <img :src="item" alt="" width="100%">
              </div>
            </div>
            <div class="setInfo" style="margin-top:10px">
              <p>1、当成本设置成功后，新获取到的新增订单对应的商品的采购成本、物流成本（头程/FBM）、其他成本，会以新设置的对应成本进行计算统计。</p>
              <p>2、如果您指向关心店铺的利润，可以直接到“其他成本”页面进行上传，上传店铺某日的真实的商品总成本，上传的总成本会计入其他成本项，参与利润计算。</p>
            </div>
            <div style="display:flex;align-items:center;margin-top:20px">
              <span style="margin-right:6px;font-weight:600">请选择成本币种:</span>
              <el-select clearable v-model="settingInfo.costCurrency" style="margin-right:10px;width:150px" size='mini' placeholder="请选择成本币种">
                <el-option 
                :label="item.value" 
                :value="item.id"
                v-for="item in currencyRateList"
                :key="item.id"
                @click.native="getRate(item.rate)"></el-option>
              </el-select>
              <span style="margin-right:30px"><span style="font-weight:600">人民币汇率:</span>&nbsp;&nbsp;{{RMBrate!=''?RMBrate:'请选择成本币种'}}</span>
              <span style="margin-right:10px"><span style="font-weight:600">转换成站点币种汇率:</span>&nbsp;&nbsp;{{stationRate!=''?stationRate:'请选择成本币种'}}</span>
            </div>
          </div>
            <div class="current">
              <div style="display:block;width:100%;border-bottom:1px solid #DCDFE6;border-right:0;text-align:center;padding:10px 0">
                当前成本
              </div>
              <div>
                <div>采购成本</div>
                <div>
                  <el-input oninput="value=value.replace(/[^\d.]/g,'')" @input="write" v-model="settingInfo.purchasePrice" style="width:200px;margin-right:70px" placeholder="请输入采购成本金额"></el-input>
                  <span>转换当前站点采购成本金额：{{afterPurchasePrice}}</span>
                </div>
              </div>
              <div>
                <div>物流成本</div>
                <div>
                  <el-input oninput="value=value.replace(/[^\d.]/g,'')" @input="write" v-model="settingInfo.headerPrice" style="width:200px;margin-right:70px" placeholder="请输入物流成本金额"></el-input>
                  <span>转换当前站点物流成本金额：{{afterHeaderPrice}}</span>
                </div>
              </div>
              <div>
                <div>其他成本</div>
                <div>
                  <el-input oninput="value=value.replace(/[^\d.]/g,'')" @input="write" v-model="settingInfo.otherFee" style="width:200px;margin-right:70px" placeholder="请输入其他成本金额"></el-input>
                  <span>转换当前站点其他成本金额：{{afterOtherFree}}</span>
                </div>
              </div>
              <div>
                <div>毛利指标</div>
                <div>
                  <el-input oninput="value=value.replace(/[^\d.]/g,'')" @input="write" v-model="settingInfo.grossProfitTarget" style="width:200px;margin-right:70px" placeholder="请输入毛利指标">
                    <span slot="append">%</span>
                  </el-input>
                  <span></span>
                </div>
              </div>
          </div>
          <div slot="footer">
            <el-button @click="Close">取 消</el-button>
            <el-button :loading='buttonLoading' type="primary" @click="sure">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import {Digits} from '@/utils/Digits.js'
import {
  listPage, 
  pageInit, 
  setCostInit, 
  set, 
  setBatch
} from '@/api/goodsManage/costGoods.js'
import ShopsAndSites from '@/components/ShopsAndSites.vue'
import GoodsImage from '@/components/GoodsImage'

export default {
  components:{
      ShopsAndSites,
      GoodsImage
  },
  data(){
    return{
      a:'',
      settingCostBox:false,
      loading:false,
      tableData:[],
      pageInfo:{
          current:'',
          shopId:'',
          marketPlaceId:'',
          status:'',
          stockStatus:'',
          pageSize:'',
      },
      total:'',
      tress:[],
      productStatusList:[],
      stockStatusList:[],
      searchList:[],
      select:'',
      keyword:'',
      currencyRateList:[],
      RMBrate:'',  //人民币汇率
      rate:'',
      settingInfo:{
        ids:[],
        costCurrency:'',
        headerPrice:'',
        otherFee:'',
        purchasePrice:'',
        grossProfitTarget:''
      },
      marketPlaceCurrencyId:'',
      buttonLoading:false,
      marketPlaceImageUrl:'',
      imageList:[],
      asin:'',
      parentAsin:'',
      commoditySku:'',
      label:'',
      pic:''
    }
  },
  computed:{
    //站点汇率
    stationRate(){
      if(!this.settingInfo.costCurrency){
        return ''
      }
      if(this.marketPlaceCurrencyId==this.settingInfo.costCurrency){
        console.log(this.marketPlaceCurrencyId,this.settingInfo.costCurrency);
        return 1
      }else {
        console.log(this.marketPlaceCurrencyId,this.settingInfo.costCurrency);
        let str=''
        this.currencyRateList.forEach(item=>{
          if(this.marketPlaceCurrencyId==item.id){
            str=item.rate
          }
        })
        //保留5位有效数字
        let stationRate=(this.RMBrate / str).toPrecision(6)
        let num = String(stationRate).split('').length - String(stationRate).split('').indexOf('.')-1
        stationRate=Digits(stationRate,num - 1)
        return stationRate
      }
    },
    afterHeaderPrice(){
      return Math.round(this.settingInfo.headerPrice * this.stationRate*100) /100
    },
    afterOtherFree(){
      return Math.round(this.settingInfo.otherFee * this.stationRate*100) /100
    },
    afterPurchasePrice(){
      return Math.round(this.settingInfo.purchasePrice * this.stationRate*100) /100
    },
    afterGrossProfitTarget(){
      return Math.round(this.settingInfo.grossProfitTarget * this.stationRate*100) /100
    }
  },
  created(){
      this.getPageInit()
      // this.getListPage()
      console.log();
  },
  methods:{
      clearType(){
          this.select=''
          this.keyword=''
      },
      getListPage(){
          let sku = ''
          if(this.select==''||this.keyword==''){
              sku=null
          }else if(this.select=='asin'){
              sku={asin:this.keyword}
          }else if(this.select=='parent_asin'){
              sku={parent_asin:this.keyword}
          }else if(this.select=='commodity_sku'){
              sku={commodity_sku:this.keyword}
          }
          this.loading=true
          listPage({...sku,...this.pageInfo}).then(res=>{
              console.log(res.data);
              this.loading=false
              this.tableData=res.data.values
              this.total=res.data.pageInfo.total
          }).catch(()=>{
              this.loading=false
          })
      },
      handleSizeChange(val){
          this.pageInfo.pageSize=val
          this.getListPage()
      },
      handleCurrentChange(val){
          this.pageInfo.current=val
          this.getListPage()
      },
      getPageInit(){
          pageInit().then(res=>{
              console.log(res.data.data.tress);
              this.tress=res.data.data.tress
              this.productStatusList=res.data.data.productStatusList
              this.stockStatusList=res.data.data.stockStatusList
              this.searchList=res.data.data.searchList
              console.log(this.tress);
          })
      },
      getID(shopID,marketPlaceId){
          this.pageInfo.shopId=shopID
          this.pageInfo.marketPlaceId=marketPlaceId
          console.log(shopID,marketPlaceId);
          this.pageInfo.current=1
          this.getListPage()
      },
      find(){
          this.pageInfo.current=1
          this.getListPage()
      },
      clear(){
          this.pageInfo.shopId=''
          this.pageInfo.marketPlaceId=''
      },
      // 多选中的ID
      handleSelectionChange(val){
        console.log(val);
        const ids=[]
        const imgs=[]
        val.forEach(item=>{
          ids.push(item.id)
          imgs.push(item.imageUrl)
        })
        this.settingInfo.ids=ids
        this.imageList=imgs
      },
      batchSettingCost(id,scope){
        if(typeof id == 'object'){
          if(this.pageInfo.marketPlaceId==''){
            this.$message.info('请查询筛选到具体店铺')
            return
          }
          if(this.settingInfo.ids.length==0){
            this.$message.warning('请选择商品')
            return
          }
          this.loading=true
          setBatch(this.pageInfo.marketPlaceId).then(res=>{
            console.log(res);
            if(res.data.code==200){
              this.loading=false
              this.settingCostBox=true
              console.log(res);
              this.currencyRateList=res.data.data.currencyRateList
              this.marketPlaceCurrencyId=res.data.data.marketPlaceCurrencyId
              this.marketPlaceImageUrl=res.data.data.marketPlaceImageUrl
              if(!this.settingInfo.costCurrency){
                this.RMBrate=''
              }
            }else {
              this.loading=false
              this.$message.warning(res.data.message)
            }
          })
        }else {
          this.settingInfo.ids=[id]
          this.asin=scope.asin
          this.label=scope.row
          this.parentAsin=scope.parentAsin
          this.commoditySku=scope.commoditySku
          this.loading=true
          this.pic=scope.imageUrl
          setCostInit(id).then(res=>{
            if(res.data.code==200){
              this.loading=false
              this.settingCostBox=true
              console.log(res);
              this.currencyRateList=res.data.data.currencyRateList
              this.settingInfo.costCurrency=res.data.data.AsinProductCostUpdateInit.costCurrency
              this.settingInfo.headerPrice=res.data.data.AsinProductCostUpdateInit.headerPrice
              this.settingInfo.otherFee=res.data.data.AsinProductCostUpdateInit.otherFee
              this.settingInfo.purchasePrice=res.data.data.AsinProductCostUpdateInit.purchasePrice
              this.marketPlaceCurrencyId=res.data.data.marketPlaceCurrencyId
              this.marketPlaceImageUrl=res.data.data.marketPlaceImageUrl
              if(!this.settingInfo.costCurrency){
                this.RMBrate=''
              }else {
                this.currencyRateList.forEach(item=>{
                  if(item.id==this.settingInfo.costCurrency){
                    this.RMBrate=item.rate
                  }
                })
              }
            }else {
              this.loading=false
              this.$message.warning(res.data.message)
            }
          })
        }
      },
      getRate(rate){
        console.log(rate);
        this.RMBrate=rate
      },
      sure(){
        this.buttonLoading=true
        set(this.settingInfo).then(res=>{
          this.buttonLoading=false
          if(res.data.code==200){
            this.settingCostBox=false
            this.$message.success(res.data.message)
            this.getListPage()
            this.Close()
          }else {
            this.buttonLoading=false
            this.$message.warning(res.data.message)
          }
        })
        this.$refs.multipleTable.clearSelection();
      },
      write(){
        if(this.settingInfo.costCurrency==''){
          this.settingInfo.headerPrice=''
          this.settingInfo.otherFee=''
          this.settingInfo.purchasePrice=''
          this.$message.warning('请先选择成本币种')
          return
        }
      },
      Close(){
        this.settingInfo={
          ids:[],
          costCurrency:'',
          headerPrice:'',
          otherFee:'',
          purchasePrice:''
        }
        this.settingCostBox=false
        this.$refs.multipleTable.clearSelection()
      }
    }
}
</script>

<style scoped lang='scss'>
.a{
  font-size: 15px;
}
.picInfo{
  display: flex;
  height: 100px;
  margin-top: -20px;
  // background: red;
  .pic{
    width: 100px;
    height: 100px;
  }
  .info{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
    span{
      display: inline-block;
      width: 200px;
    }
  }
}
.setInfo{
  p{
    line-height: 20px;
    color: #909399;
    margin-bottom: 10px;
  }
}
.noPic{
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size:8px;
    color: #909399;
    margin-right: 15px;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.goodsInfo{
    display: flex;
    >div:nth-of-type(2){
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    p{
        font-size: 12px;
        color: #909399;
    }
}
.pic{
    width:160px;
    height: 160px;
    font-size:8px;
    color: #909399;
    text-align: center;
    line-height: 160px;
    margin-right: 10px;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
    margin: 15px 0 20px 0;
}
.suspend{
    p{
        span{
            display: inline-block;
            width: 190px;
            margin-right: 10px;
            font-size: 13px;
        }
    }
}
img{
    vertical-align: middle;
}
.current{
  width: 100%;
  border: 1px solid #DCDFE6;
  border-bottom: 0;
  margin-top: 10px;
  >div{
    display: flex;
      border-bottom: 1px solid #DCDFE6;
    >div:nth-of-type(1){
      width:150px;
      text-align: center;
      border-right: 1px solid #DCDFE6;
    }
    >div:nth-of-type(2){
      display: flex;
      padding-left: 50px;
      flex: 1;
    }
    >div{
      padding: 6px;
      line-height: 30px;
    }
  }
}
</style>