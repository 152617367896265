<template>
  <div style="width: 100%">
    <div style="width: 100%; text-align: left; display: flex">
      <el-popover placement="right" width="400" trigger="click">
        <div style="display:flex">
          <div class="bigImg">
            <div
              @click="bigFind"
              class="shade el-icon-circle-plus-outline"
            ></div>
            <img :src="img" alt="" width="100%" />
          </div>
          <div style="flex: 1">
            <p class="twoLine">{{ title }}</p>
            <div class="bigInfo">
                <span style="font-size: 12px">
                    <img :src="marketPlaceImageUrl" alt="" width="20px" style="margin-top:2px">
                    子：{{ asin }}</span>
                <span style="font-size: 12px">SKU：{{ commoditySku }}</span><br>
                <span style="font-size: 12px">品牌：{{ label }}</span>
                <span style="font-size: 12px">父ASIN：{{ parentASIN }}</span>
            </div>
          </div>
        </div>
        <div @click="getImg(imageUrl)" slot="reference" class="pic">
          <img :src="imageUrl" alt="" width="100%" height="100%" />
        </div>
      </el-popover>
      <div style="display: inline-block">
        <p>
          <span class="state" style="width: 23px">
            <img
              :src="marketPlaceImageUrl"
              alt=""
              width="100%"
            />
          </span>
          <span style="font-size: 12px">子：{{ asin }}</span>
        </p>
        <p>
          <span style="font-size: 12px">SKU：{{ commoditySku }}</span>
        </p>
      </div>
    </div>
    <ElImageViewer
      :z-index="9999999"
      :url-list="imgList"
      v-if="showViewer"
      :on-close="closeViewer"
    ></ElImageViewer>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer.vue";
export default {
  components: {
    ElImageViewer
  },
  props: ["asin", "commoditySku", "imageUrl", "title",'label','parentASIN'],
  data() {
    return {
      img: "",
      imgList: [],
      showViewer: false
    };
  },
  methods: {
    bigFind() {
      this.showViewer = true;
      const img = this.img.replace("SL150", "SL600");
      this.imgList = [img];
      console.log(this.imgList);
    },
    closeViewer() {
      this.showViewer = false;
      this.imgList = [];
    },
    getImg(img) {
      this.img = img.replace("SL150", "SL300");
    }
  }
};
</script>

<style scoped lang='scss'>
.state {
  display: inline-block;
  width: 24px;
  height: 16px;
  vertical-align: middle;
  margin-top: -4px;
  img {
    margin-top: 3px;
  }
}
.pic {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 4px;
  img {
    width: 100%;
    height: 100%;
  }
}
.bigImg {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
}
.shade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  color: white;
  text-align: center;
  line-height: 100px;
  font-size: 30px;
}
.bigImg:hover {
  .shade {
    display: block;
  }
}
::v-deep .el-image-viewer__wrapper {
  z-index: 99999999;
}
.twoLine{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
}
.bigInfo{
    margin-top: 10px;
    margin-left:10px;
    >span{
        display: inline-block;
        width: 48%;
    }
}
</style>